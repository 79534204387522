<template>
  <custom-page>

    <group-card title="custom.page1.芯体">

      <el-form :model="form" :rules="rules" ref="form">
        <!--长-->
        <el-form-item prop="length">
          <group-item label="custom.page1.长">
            <group-item-column>
              <!--<el-input type="number" size="small" autosize v-model.number="form.length"/>-->
              <el-input type="number" size="small" autosize v-model.number="form.length"/>
            </group-item-column>
            <group-item-column min-width="100">mm</group-item-column>
          </group-item>
        </el-form-item>

        <!--宽-->
        <el-form-item prop="height">
          <group-item label="custom.page1.高">
            <group-item-column>
              <!--<el-input type="number" size="small" autosize v-model.number="form.height"/>-->
              <el-input type="number" size="small" autosize v-model.number="form.height"/>
            </group-item-column>
            <group-item-column min-width="100">mm</group-item-column>
          </group-item>
        </el-form-item>

      </el-form>

      <!--示例图-->
      <sample-image :src="sizeImage"/>

      <!--排数-->
      <group-item label="custom.page1.排数">
        <el-radio-group v-model="form.bank">
          <el-radio :label="1">1</el-radio>
          <el-radio :label="2">2</el-radio>
        </el-radio-group>
      </group-item>


      <!--扁管-->
      <group-item label="custom.page1.扁管">
        <table-selct :list="tubeList" :column-option="tubeColumnOpt" :show-supplier="true" @select="handleTubeSelect"/>
      </group-item>
      <sample-image src="Tube.jpg"/>

      <!--翅片-->
      <group-item label="custom.page1.翅片" v-if="form.tube">
        <table-selct :list="finList" :column-option="finColumnOpt" @select="handleFinSelect"/>
      </group-item>
      <sample-image src="Fin.jpg" v-if="form.tube"/>

    </group-card>

    <div class="action-bar">
      <el-button type="" class="btn" @click="handlePrevClick">{{ $t("common.actions.pre") }}</el-button>
      <el-button type="primary" class="btn" @click="handleNextClick" :disabled="!form.fin" :loading="loading">
        {{ $t("custom.page1.计算性能") }}
      </el-button>
    </div>
  </custom-page>
</template>

<script>
import CustomPage from "@/views/Custom/components/CustomPage";
import GroupCard from "@/views/Custom/components/GroupCard";
import GroupItem from "@/views/Custom/components/GroupItem";
import GroupItemColumn from "@/views/Custom/components/GroupItemColumn";
import SampleImage from "@/views/Custom/components/SampleImage";
import TableSelct from "@/views/Custom/components/TableSelct";
import Fetch from "@/api/fetch";
import {generatePartOtherObj, getGoodsCustomMode} from "@/utils";
import mixin from "@/views/Custom/components/mixins/mixins";
import {ORDER_MODULE_MPF_USER} from "../../../../constant";
import CalculatePerformance from "@/views/Custom/components/calculate/performance/performance";
import {mapGetters} from "vuex";

export default {
  name: "page1",
  components: {TableSelct, SampleImage, GroupItemColumn, GroupItem, GroupCard, CustomPage},
  data() {
    return {
      loading: false,
      min: {l: 100, h: 100,},
      max: {l: 1000, h: 1000,},
      type: this.$route.query.type,
      module: null,
      tubeList: [],
      finList: [],
      tubeColumnOpt: [
        {title: 'custom.page1.宽', prop: 'width'},
        {title: 'custom.page1.高', prop: 'height'},
        {title: 'custom.page1.孔数', prop: 'portNum'},
		    {title: 'custom.page1.爆破压力', prop: 'pressure'},
      ],
      finColumnOpt: [
        {title: 'custom.page1.宽', prop: 'width'},
        {title: 'custom.page1.高', prop: 'height'},
        {title: 'custom.page1.FPI', prop: 'fpi'},
      ],
      form: {
        length: "",
        height: "",
        bank: 1,
        tube: null,
        fin: null,
      },
      rules: {
        length: [{type: 'number', required: true, max: '', min: 1, message: '格式不正确', trigger: ['blur', 'change']},],
        height: [{type: 'number', required: true, max: 1000, min: 1, message: '', trigger: ['blur', 'change']},]
      },
    }
  },
  mixins: [mixin],
  computed: {
    ...mapGetters(["customInfo"]),
    sizeImage() {
      if (this.type == 0)
        return 'MC-LH.jpg'
      else if (this.type == 1)
        return 'MCS-LH.jpg'
    }
  },
  mounted() {
	  
	  
	  
    this.module = getGoodsCustomMode(this.type)

    if (this.type == 0) {
      this.rules.height[0].max = 1000;
	  this.rules.length[0].message =  this.$t("newadd.t.格式不正确"); 
      this.rules.height[0].message =  this.$t("newadd.t.最大高度不能超过")+"1000"; 
    } else if (this.type == 1) {
      this.rules.height[0].max = 300;
	  this.rules.length[0].max = 300;  
	  this.rules.length[0].message =  this.$t("newadd.t.最大长度不能超过")+"300"; 
      this.rules.height[0].message =  this.$t("newadd.t.最大高度不能超过")+"300"; 
    }

    this.getTubeList()
  },
  methods: {
    getTubeList() {
      this.$req.get("/client/goods/part/matchFlatTube", {module: this.module}).then(res => {
        res.forEach(generatePartOtherObj)
        this.tubeList = res;
			
      })
    },
    getFinList() {
      let tube = this.form.tube;
      let params = {
        module: this.module,
        clazz: tube.otherObj.class.value,
        supplierId: tube.supplierId
      }
      this.$req.get("/client/goods/part/matchFin", params).then(res => {
        res.forEach(generatePartOtherObj)
        this.finList = res;
      })
    },
    handleTubeSelect(tube) {
      this.form.tube = tube;
      this.getFinList();
    },
    handleFinSelect(fin) {
      this.form.fin = fin;
    },

    async matchCollectionTube() {
      try {
        await this.$refs.form.validate();

        let {fin, tube: flatTube} = this.form
        let params = {
          finHeight: fin.otherObj.height.value,
          supplierId: flatTube.supplierId,
          tubeHeight: flatTube.otherObj.height.value,
          tubeWidth: flatTube.otherObj.width.value,
          module: this.module
        }
        let res = await this.$req.get("/client/goods/part/matchCollectorTube", params)

        if (!res || res.length == 0) {
          this.$message.error("未找到相匹配的集液管，请更改参数后重试。")
          return false;
        }
        let collectorTube = generatePartOtherObj(res[0])

        this.$store.commit("SET_CUSTOM_INFO", {
          supplierId: flatTube.supplierId,
          supplierName: flatTube.supplierName,
          collectorTube,
          flatTube,
          fin
        })

        console.log("准备计算")
        return (await this.handleCalculate());
      } catch (e) {
        console.error(e)
        return false;
      }
    },
    async handleCalculate() {
      try {
        let url = "/client/goods/calc/" +
            (this.module === ORDER_MODULE_MPF_USER ? "calcMpfUser" : "calcMstUser")
        let {work, supplierId, collectorTube, flatTube, fin} = this.customInfo;
        let {height, length, bank: rowNumber} = this.form;
        let calc = new CalculatePerformance();
        await calc.setUnit();
        calc = calc.setFin(fin)
            .setMD(collectorTube)
            .setTube(flatTube)
            .setSize(height, length)
            .setBank(rowNumber)
            .setWork(work)
			.setIFanUser(work)
            .get();
        let res = await this.$req.post(url, calc);
		
        this.$store.commit("SET_CUSTOM_INFO", {calcRes: res, calcModel: calc, height, length, rowNumber})

        return true;
      } catch (e) {
        return false;
      }
    },

    async beforeNext() {
      this.loading = true;
      let res = await this.matchCollectionTube()
      this.loading = false;
      console.log(res)
      return res;
      // return true;
    },
  },
}
</script>

<style scoped lang='scss'>

.action-bar {
  text-align: center;

  .btn {
    width: 330px;
  }
}

</style>