import store from "@/store";

class CalculatePerformance {
  data = {
    sunit: {},
    iPatt: null,
    sRefName: null,
    dRefInP: null,
    dRefInT: null,
    dRefOutSC: null,
    dRefVF: null,
    dAirInT: null,
    dAirInV: null,
    iFanNum: 0,
	iFanUser:null,
    lFanCurve: [],
    dLmax: null,
    dHmax: null,
    iBank: null,
    dTube: {},
    dMD: {},
    dFin: {}
  }

  constructor() {
  }

  async setUnit() {
    let userUnit = await store.dispatch("GetUserUnitOrCache");
    let {
      airPressureDrop, charge, heatLoad, length, mass, massFlow,
      pressure, refPressureDrop, temperature, velocity, volumeFlow
    } = userUnit;
    this.data.sunit.Q = heatLoad
    this.data.sunit.T = temperature
    this.data.sunit.P = pressure
    this.data.sunit.DPr = refPressureDrop
    this.data.sunit.DPa = airPressureDrop
    this.data.sunit.V = velocity
    this.data.sunit.VFlow = volumeFlow
    this.data.sunit.MFlow = massFlow
    this.data.sunit.Chag = charge

    return this;
  }

  setFin(fin) {
    // let {img, module, other, otherObj, status, supplierId, titleEnUs, titleZhCn, type,} = fin
    let {fpi, height, louverAngle, louverLength, louverPitch, thickness, weightM, width} = fin.otherObj
    this.setNumber('dFin', 'h', height.value)
    this.setNumber('dFin', 'w', width.value)
    this.setNumber('dFin', 'thick', thickness.value)
    this.setNumber('dFin', 'fpi', fpi.value)
    this.setNumber('dFin', 'lp', louverPitch.value)
    this.setNumber('dFin', 'll', louverLength.value)
    this.setNumber('dFin', 'la', louverAngle.value)

    return this;
  }

  setMD(md) {
    let {manifoldOD, thickness} = md.otherObj
    this.setNumber('dMD', 'OD', manifoldOD.value)
    this.setNumber('dMD', 'delta', thickness.value)
    return this;
  }

  setTube(tube) {
    let {width, height, portNum, portWidth, portHeight} = tube.otherObj
    this.setNumber('dTube', 'h', height.value)
    this.setNumber('dTube', 'w', width.value)
    this.setNumber('dTube', 'n', portNum.value)
    this.setNumber('dTube', 'wp', portWidth.value)
    this.setNumber('dTube', 'hp', portHeight.value)
    return this;
  }

  setSize(height, length) {
    this.setNumber('dHmax', null, height)
    this.setNumber('dLmax', null, length)
    return this;
  }

  setBank(bank) {
    this.setNumber('iBank', null, bank)
    return this;
  }

  setWork(work) {
    let {
      HXPatt, ambientTemperature, exhaustPressure, exhaustTemperature, fanCurve,
      refrigerant, supercooling, refvolflow, windSpeed, useDiyFanCurve, fanNum
    } = work
    this.setNumber('iPatt',null,HXPatt)
    // 冷媒
    this.setString('sRefName', null, refrigerant)
    // 排气压力
    this.setNumber('dRefInP', null, exhaustPressure)
    // 排气温度
    this.setNumber('dRefInT', null, exhaustTemperature)
    // 过冷度
    this.setNumber('dRefOutSC', null, supercooling)
    //流量
    this.setNumber('dRefVF', null, refvolflow)
    // 环境温度
    this.setNumber('dAirInT', null, ambientTemperature)

    if (useDiyFanCurve) {
      let arr = [];
      for (let i = 1; i <= 8; i++) {
        let vf = fanCurve[`v${i}`];
        let dp = fanCurve[`p${i}`];
        if (vf === 0 && dp === 0) continue;
        arr[i - 1] = {vf, dp}
      }
      // 风机曲线
      this.data.lFanCurve = arr;
      // 风机数量
      this.setNumber('iFanNum', null, fanNum)

    } else {
      // 风速
      this.setNumber('dAirInV', null, windSpeed)
    }

    return this;
  }


  setNumber(obj, attr, value) {
    this.setValue(obj, attr, typeof value === 'number' ? value : Number(value))
  }

  setString(obj, attr, value) {
    this.setValue(obj, attr, typeof value === 'string' ? value : (value + ''))
  }

  setValue(obj, attr, value) {
    if (attr != null) this.data[obj][attr] = value;
    else this.data[obj] = value;
  }

  setIFanUser(FanVersion){
      var windType ='';
	  if(!FanVersion.windType){
		 
	  }else{
		  windType=FanVersion.windType==1?0:1;
	  }
	  
	  this.setValue('iFanUser', null, windType);
	  return this;
  }

  get() {
    return this.data
  }
}


export default CalculatePerformance